<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/fitting-sku/page">
      <el-button
        v-if="$auth('sku新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>
      <el-button
        v-if="$auth('sku导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.type','规格型号')">
            <el-input v-model.trim="filter.specModel"></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','SKU名称')">
            <el-input v-model.trim="filter.name" type="userType"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="SKU名称" align="center"></el-table-column>
      <el-table-column prop="code" label="货号" align="center"></el-table-column>
      <el-table-column prop="specModel" label="规格型号" align="center"></el-table-column>
      <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('sku编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('sku删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/fittingSku.xlsx"
        action="basic/fitting-sku/import"
        name="电梯配件sku导入"
        download-name="电梯配件sku模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./AccessorySKUEdit";

  export default {

    components: {EditPage},
    props:{
      query:Object,
    },
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          faultCode:"",
          status: "",
        },
        visible:false,
      };
    },
    watch:{
      query:{
        handler(params) {
          // console.log(params,139);
          for (let [key,value] of Object.entries(params)) {
            if (key==="faultCode") {

              if (value.indexOf("E0")===0) {
                this.filter.faultCode=value.replace("E0","");
              }else {
                this.filter.faultCode=value;
              }
              this.getList(1);
            }
          }
        },
        deep:true,
        // immediate:true,
      },
    },
    mounted() {

      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },

      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/fitting-sku", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
     
    },
  };
</script>
<style lang="scss" scoped>
</style>
