<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('电梯配件管理品牌')" label="品牌" name="first"><accessories-management></accessories-management></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯配件管理产品')" label="产品" name="second"><accessories-product></accessories-product></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯配件管理sku')" label="SKU" name="third"><accessories-sku></accessories-sku></el-tab-pane>
  </el-tabs>
</template>
<script>
  import AccessoriesManagement from "@/views/basic/AccessoriesManagement";
  import AccessoriesProduct from "@/views/basic/AccessoriesProduct";
  import AccessoriesSku from "@/views/basic/AccessoriesSku";
  export default {
    name: "AccessoriesIndex",
    components:{AccessoriesManagement,AccessoriesProduct,AccessoriesSku},
  };
</script>

<style scoped>

</style>